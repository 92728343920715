import React from "react"
import { graphql } from "gatsby";
import { Container, Box, Grid } from "@material-ui/core";

import { makeStyles } from '@material-ui/core/styles';
import CollapseIcon from '../components/CollapseIcon';

import Layout from "../components/layout"
import SEO from "../components/seo"
import { PageTitle } from '../components/PageTitle'
import { Document } from "../styles/pages/documentos";

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
}));

export default function DocumentsPage(props) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(true);

  const handleClick = () => {
    setOpen(!open);
  };



  const editais = props.data.editais.edges
  const ppcs = props.data.ppcs.edges
  const horarios2020 = props.data.horarios2020.edges
  const horarios2021 = props.data.horarios2021.edges
  const matrizes = props.data.matrizes.edges
  const normas = props.data.normas.edges
  const pdi = props.data.pdi.edges
  const portarias = props.data.portarias.edges
  const regimentos = props.data.regimentos.edges
  const calendario = props.data.calendario.edges
  const bolsas = props.data.bolsas.edges
  const cpa = props.data.cpa.edges
  const periodicos = props.data.periodicos.edges

  return (
    <Layout>
      <SEO title="Documentos Institucionais" />
      <Container style={{ marginTop: 50 }}>
        <PageTitle>Documentos Institucionais</PageTitle>
        <Grid container>
          <Grid xs={12} sm={12}>
            <CollapseIcon
              head="Editais"
              icon="fa fa-folder"
              items={editais.map(({ node }) => ({
                "name": node.name, "to": node.publicURL, "type": "external", "icon": "fa fa-link"
              }))}
            />
          </Grid>
          <Grid xs={12} sm={12}>
            <CollapseIcon
              head="PPCs"
              icon="fa fa-folder"
              items={ppcs.map(({ node }) => ({
                "name": node.name, "to": node.publicURL, "type": "external", "icon": "fa fa-link"
              }))}
            />
          </Grid>
          <Grid xs={12} sm={12}>
            <CollapseIcon
              head="Periódicos"
              icon="fa fa-folder"
              items={periodicos.map(({ node }) => ({
                "name": node.name, "to": node.publicURL, "type": "external", "icon": "fa fa-link"
              }))}
            />
          </Grid>
          <Grid xs={12} sm={12}>
            <CollapseIcon
              head="Horários 2020"
              icon="fa fa-folder"
              items={horarios2020.map(({ node }) => ({
                "name": node.name, "to": node.publicURL, "type": "external", "icon": "fa fa-link"
              }))}
            />
          </Grid>
          <Grid xs={12} sm={12}>
            <CollapseIcon
              head="Horários 2021"
              icon="fa fa-folder"
              items={horarios2021.map(({ node }) => ({
                "name": node.name, "to": node.publicURL, "type": "external", "icon": "fa fa-link"
              }))}
            />
          </Grid>
          <Grid xs={12} sm={12}>
            <CollapseIcon
              head="Matrizes"
              icon="fa fa-folder"
              items={matrizes.map(({ node }) => ({
                "name": node.name, "to": node.publicURL, "type": "external", "icon": "fa fa-link"
              }))}
            />
          </Grid>
          <Grid xs={12} sm={12}>
            <CollapseIcon
              head="Normas e Regulamentos"
              icon="fa fa-folder"
              items={normas.map(({ node }) => ({
                "name": node.name, "to": node.publicURL, "type": "external", "icon": "fa fa-link"
              }))}
            />
          </Grid>
          <Grid xs={12} sm={12}>
            <CollapseIcon
              head="PDI"
              icon="fa fa-folder"
              items={pdi.map(({ node }) => ({
                "name": node.name, "to": node.publicURL, "type": "external", "icon": "fa fa-link"
              }))}
            />
          </Grid>
          <Grid xs={12} sm={12}>
            <CollapseIcon
              head="Portarias"
              icon="fa fa-folder"
              items={portarias.map(({ node }) => ({
                "name": node.name, "to": node.publicURL, "type": "external", "icon": "fa fa-link"
              }))}
            />
          </Grid>
          <Grid xs={12} sm={12}>
            <CollapseIcon
              head="Regimento Geral"
              icon="fa fa-folder"
              items={regimentos.map(({ node }) => ({
                "name": node.name, "to": node.publicURL, "type": "external", "icon": "fa fa-link"
              }))}
            />
          </Grid>
          <Grid xs={12} sm={12}>
            <CollapseIcon
              head="Calendário Acadêmico"
              icon="fa fa-folder"
              items={calendario.map(({ node }) => ({
                "name": node.name, "to": node.publicURL, "type": "external", "icon": "fa fa-link"
              }))}
            />
          </Grid>

          <Grid xs={12} sm={12}>
            <CollapseIcon
              head="Bolsas"
              icon="fa fa-folder"
              items={bolsas.map(({ node }) => ({
                "name": node.name, "to": node.publicURL, "type": "external", "icon": "fa fa-link"
              }))}
            />
          </Grid>

          <Grid xs={12} sm={12}>
            <CollapseIcon
              head="CPA"
              icon="fa fa-folder"
              items={cpa.map(({ node }) => ({
                "name": node.name, "to": node.publicURL, "type": "external", "icon": "fa fa-link"
              }))}
            />
          </Grid>
        </Grid>
      </Container>
    </Layout>
  )
}

export const pagequery = graphql`
  query {
    editais: allFile(filter: {relativeDirectory: {eq: "Editais"}}) {
      edges {
        node {
          name
          publicURL
        }
      }
    }
    ppcs: allFile(filter: {relativeDirectory: {eq: "PPCs"}}) {
      edges {
        node {
          name
          publicURL
        }
      }
    }
     horarios2020: allFile(filter: {relativeDirectory: {eq: "Horarios-2020"}}) {
      edges {
        node {
          name
          publicURL
        }
      }
    }
     horarios2021: allFile(filter: {relativeDirectory: {eq: "Horarios-2021"}}) {
      edges {
        node {
          name
          publicURL
        }
      }
    }
     matrizes: allFile(filter: {relativeDirectory: {eq: "Matrizes"}}) {
      edges {
        node {
          name
          publicURL
        }
      }
    }
     normas: allFile(filter: {relativeDirectory: {eq: "Normas-e-Regulamentos"}}) {
      edges {
        node {
          name
          publicURL
        }
      }
    }
     pdi: allFile(filter: {relativeDirectory: {eq: "PDI-e-Edital"}}) {
      edges {
        node {
          name
          publicURL
        }
      }
    }
     portarias: allFile(filter: {relativeDirectory: {eq: "Portarias"}}, sort: {fields: name, order: DESC}) {
      edges {
        node {
          name
          publicURL
        }
      }
    }
     regimentos: allFile(filter: {relativeDirectory: {eq: "Regimentos"}}) {
      edges {
        node {
          name
          publicURL
        }
      }
    }
     calendario: allFile(filter: {relativeDirectory: {eq: "Calendario-Academico"}}) {
      edges {
        node {
          name
          publicURL
        }
      }
    }
    bolsas: allFile(filter: {relativeDirectory: {eq: "Bolsas"}}) {
      edges {
        node {
          name
          publicURL
        }
      }
    }
    cpa: allFile(filter: {relativeDirectory: {eq: "CPA"}}) {
      edges {
        node {
          name
          publicURL
        }
      }
    }
    periodicos: allFile(filter: {relativeDirectory: {eq: "Periodicos"}}) {
      edges {
        node {
          name
          publicURL
        }
      }
    }
  }
`


